var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"must-sort":"","fixed-header":"","show-expand":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.instructions,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
    itemsPerPageOptions: [ 15, 50, 100, 200 ],
    itemsPerPageText: `Instructions ${ _vm.$t('per page') }:`
  },"no-data-text":"No instructions found"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pt-3 px-3 mb-n3",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"3"}},[_c('search-bar',{attrs:{"value":_vm.options.search},on:{"update:value":function($event){return _vm.$set(_vm.options, "search", $event)}}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('table-filter',{attrs:{"value":_vm.options.filter.key,"choices":[
            'alternatives',
            'one_choice_explained',
            'treated_when',
            'considerations',
            'treated_length',
            'infection_control',
          ],"label":"Key","icon":"fal fa-key","multiple":""},on:{"update:value":function($event){return _vm.$set(_vm.options.filter, "key", $event)}}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('table-filter',{attrs:{"value":_vm.options.filter.languages,"choices":_vm.$languages,"label":"Language","icon":"fal fa-language","multiple":""},on:{"update:value":function($event){return _vm.$set(_vm.options.filter, "languages", $event)}}})],1)],1)]},proxy:true},{key:"item.key",fn:function({ item }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.key.replaceAll('_',' ')))+" ")])]}},{key:"item.instruction",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.instruction.en)}})]}},{key:"item.interpretations_count",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.interpretations_count))+" ")]}},{key:"item.translation_count",fn:function({ item }){return [_vm._v(" "+_vm._s(Object.keys(item.instruction).length)+" ")]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","ripple":"","icon":"","plain":""},on:{"click":function($event){return _vm.$emit('toggle-right-drawer', item, true)}}},[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}},{key:"page-text",fn:function(props){return [_vm._v(" Showing "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" instructions ")]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }