<template>
  <v-data-table
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="headers"
    :items="instructions"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `Instructions ${ $t('per page') }:`
    }"
    no-data-text="No instructions found"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col cols="2">
          <table-filter
            :value.sync="options.filter.key"
            :choices="[
              'alternatives',
              'one_choice_explained',
              'treated_when',
              'considerations',
              'treated_length',
              'infection_control',
            ]"
            label="Key"
            icon="fal fa-key"
            multiple
          />
        </v-col>
        <v-col cols="2">
          <table-filter
            :value.sync="options.filter.languages"
            :choices="$languages"
            label="Language"
            icon="fal fa-language"
            multiple
          />
        </v-col>
      </v-row>
    </template>
    <template #item.key="{ item }">
      <v-chip small>
        {{ item.key.replaceAll('_',' ') | capitalize }}
      </v-chip>
    </template>
    <template #item.instruction="{ item }">
      <span v-html="item.instruction.en" />
    </template>
    <template #item.interpretations_count="{ item }">
      {{ item.interpretations_count | numeral }}
    </template>
    <template #item.translation_count="{ item }">
      {{ Object.keys(item.instruction).length }}
    </template>
    <template #item.actions="{ item }">
      <v-btn
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
    <!-- <template #expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col>
            <div class="text-body-2">
              {{ item.title }}
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle><v-icon>fal fa-disease</v-icon> Organisms</v-list-item-subtitle>
                <v-list-item-title v-if="item.organisms.length > 0">
                  <v-chip
                    v-for="(organism, index) in item.organisms"
                    :key="index"
                    v-bind="$attrs"
                    outlined
                    :small="true"
                    class="mr-1 mb-1 px-2"
                    x-small
                    v-text="organism.name"
                  />
                </v-list-item-title>
                <v-list-item-title v-else>
                  No associated organisms found
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template> -->

    <template #page-text="props">
      Showing {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }} instructions
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'instructions',
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
          sortBy: ['key'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Key',
            value: 'key',
          },
          {
            text: 'Instruction',
            value: 'instruction',
          },
          {
            text: 'Interpretations',
            value: 'interpretations_count',
          },
          {
            text: 'Translations',
            value: 'translation_count',
          },
          {
            sortable: false,
            align: "right",
            value: "actions",
          },
        ],
        instructions: [],
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchInstructions()
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-instructions', () => {
        this.fetchInstructions()
      })
    },
    methods: {
      fetchInstructions () {
        this.loading = true
        const promise = this.axios.get('admin/instructions', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'asc' : 'desc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })
        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchInstructions()
            return
          }
          this.totalItems = response.data.total
          this.options.page = response.data.current_page
          this.options.itemsPerPage = Number(response.data.per_page)
          this.instructions = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
